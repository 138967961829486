import { groupFeedResponse } from '../mock/feed';

export const GROUP_URL = '/api/v1/groups';

export type FeedPageProps = {
  pages: {
    hasNext: boolean;
    endCursor: string | undefined;
    data: {
      posts: typeof groupFeedResponse.posts;
    };
  }[];
  pageParams: typeof groupFeedResponse;
};
